import React, { useState, useEffect, useRef } from 'react';
import './style/Navbar.css'; // импортируйте стили, если необходимо
// import logo from './img/alpha_logo_white_mini.png'; // Импорт логотипа

const Navbar = () => {
  return (
    <nav>
      <div className='nav center'>
        <div className='logo'>WELL.com</div>
        <div className='navbar-items'>
          <a href='#'>Главная</a>
          <a href='#about'>О нас</a>
          <a href='#courses'>Курсы</a>
          <a href='#apply'>Оставить заявку</a>
          <a href='#contact'>Контакты</a>
        </div>
        <div className='navbar-fb-link'>
          <a href="https://t.me/yourprofile" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-telegram"></i>
          </a>
          <a href="https://vk.com/spbspeakkupchino" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-vk"></i>
          </a>
          <a href="https://wa.me/79110141917" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-whatsapp"></i>
          </a>
          <a href='#apply' className='apply'>Оставить заявку</a>
        </div>
      </div>
      {/* Мобильная версия */}
      <div class="menu">
        <input type="checkbox" id="burger-checkbox" class="burger-checkbox" />
        <label for="burger-checkbox" class="burger"></label>
        <ul class="menu-list">
          <li><a href="#" class="menu-item">Главная</a></li>
          <li><a href="#about" class="menu-item">О нас</a></li>
          <li><a href="#courses" class="menu-item">Курсы</a></li>
          <li><a href="#apply" class="menu-item">Оставить заявку</a></li>
          <li><a href="#" class="menu-item">Контакты</a></li>
        </ul>
        <h2>WELL.com</h2>
      </div>
    </nav>
  );
};

export default Navbar;
