import React from 'react';
import './style/header.css';
import header_gif from './img/header-entrance.png';
import searching_bar from './img/searchbar.png'

function Header() {
  return (
    <div className='header'>
      <div className='header-site'>
        <div className='center header-flex'>
          <div className='header-content'>
            <h1>W<i>W</i><b>LL</b>.c<img src={searching_bar} className='searching_bar' />m -</h1>
            <h3>Учись говорить, учись кодить</h3>
            <p>Откройте новые горизонты, учитесь легко<br /> и эффективно только с нами.</p>
            <a href='#apply'>Оставить заявку</a>
          </div>
          <div className='header-gif'><img src={header_gif} /></div>
        </div>
      </div>
      <div className='header-bottom center'>
        <div className='header-bottom-items'>
          <div className='hb-item'>
            <div className='hb-item-flex'>
              <div className='hb-icon'><i class="fa fa-users" aria-hidden="true"></i></div>
              <div className='hb-text'><b>Вчера</b><br />Курсы английского языка</div>
            </div>
          </div>
          <div className='hb-item'>
            <div className='hb-item-flex'>
              <div className='hb-icon'><i class="fa fa-building" aria-hidden="true"></i></div>
              <div className='hb-text'><b>Сегодня</b><br />Образовательный центр</div>
            </div>
          </div>
          <div className='hb-item'>
            <div className='hb-item-flex'>
              <div className='hb-icon'><i class="fa fa-globe" aria-hidden="true"></i></div>
              <div className='hb-text hb-textt'><b>Завтра</b><br />Образовательный центр №1 в РФ</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
