import React from 'react';
import SchoolName from './SchoolName';
import FeedbackForm from './FeedbackForm';
import Courses from './Courses';
import './style/Main.css'
import './style/Courses.css'
// import logo from './img/alpha_logo_white.png'; // Импорт логотипа
import about_vector from './img/about-logo2.png';


function Main() {
    return (
        <div className='main'>
            <div className='pad' id='about'>
                <div className='about'>
                    <div className='center about-all'>
                        <h2>О нас</h2>
                        <div className='about-flex'>
                            <div className='about-content'>
                                <p><b>WELL.com</b> — это место, где обучение встречается с инновациями и индивидуальным подходом к каждому ученику.</p> <p> Мы предлагаем курсы английского языка и программирования для детей и взрослых, которые помогут вам и вашим детям обрести уверенность в знаниях и навыках, необходимых в современном мире.</p>
                            </div>
                            <div className='about-card'>
                                <div className='about-item ai1'>
                                    <div className='hb-item-flex'>
                                        <div className='hb-icon'><i class="fa fa-user" aria-hidden="true"></i></div>
                                        <div className='hb-text'><b>150+</b><br />Постоянных студентов</div>
                                    </div>
                                </div>
                                <div className='about-item ai2'>
                                    <div className='hb-item-flex'>
                                        <div className='hb-icon'><i class="fa fa-heart" aria-hidden="true"></i></div>
                                        <div className='hb-text'><b>6+</b><br />лет успешной работы</div>
                                    </div>
                                </div>
                                <div className='about-item ai3'>
                                    <div className='hb-item-flex'>
                                        <div className='hb-icon'><i class="fa fa-graduation-cap" aria-hidden="true"></i></div>
                                        <div className='hb-text'><b>245+</b><br />Выпускников центра</div>
                                    </div>
                                </div>
                                <div className='about-item ai4'>
                                    <div className='hb-item-flex'>
                                        <div className='hb-icon'><i class="fa fa-certificate" aria-hidden="true"></i></div>
                                        <div className='hb-text'><b>530+</b><br />Отчетных проектов</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Курсы */}
            <Courses />
            {/* Форма обратной связи */}
            <FeedbackForm />
            {/* <SchoolName /> */}
            {/* Футер */}
            <footer className="footer" id='contact'>
                <div className="footer-content">
                    {/* <img src='{logo}' alt="Логотип" className="footer-logo" /> */}
                    <div className="footer-contacts">
                        <h4>Контакты</h4>
                        <p>Телефон: +7 (911) 014-19-17</p>
                        <p>Email: info@example.com</p>
                        <div className="footer-socials">
                            <a href="https://t.me/yourprofile" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-telegram"></i>
                            </a>
                            <a href="https://vk.com/spbspeakkupchino" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-vk"></i>
                            </a>
                            <a href="https://wa.me/79110141917" target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-whatsapp"></i>
                            </a>
                        </div>
                    </div>
                    <div className="footer-address">
                        <h4>Адрес</h4>
                        <p>г. Санкт-Петербург, Балканская площадь, 5Я</p>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Main;
